@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo3D {
  animation-name: logo3D-animation;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: 'absolute';
  top: 50px;
  left: 15px;
}

@keyframes logo3D-animation {
  from {
    top: 50px;
  }
  to {
    top: 220px;
  }
}