.AppDark {
  background-color: #0d0d0e;
}

.AppLight {
  background-color: #fafafa;
}

.Content {
  max-width: 1320px;
  margin: 0 auto;
  min-height: 80vh;
}

.ProjectContent {
  max-width: 1355px;
  margin: 0 auto;
  min-height: 80vh;
}

.custom-scrollbar {
  overflow-x: auto; /* Enables horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(68, 67, 67, 0.5) transparent; /* Scrollbar handle and background for Firefox */

  /* Styles for Chrome, Safari, and Opera */
  ::-webkit-scrollbar {
    height: 8px; /* Height for horizontal scrollbar */
    background: transparent; /* Transparent background on the track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* Dark scrollbar handle */
    border-radius: 10px; /* Rounded corners on the scrollbar handle */
    border: 2px solid transparent; /* Creates a transparent border around the handle */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker handle on hover */
  }
}
